<template>
  <div class="blank_asset3">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">지급처정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>지급처</th>
            <td>{{ unescape(partnerName) }}</td>
            <th>사업자/주민등록번호</th>
            <td>{{ corporateNum | corporateOrPersonalNum }}</td>
          </tr>
          <tr>
            <th>사업자/주민등록번호 사본</th>
            <td colspan="3">
              <FileView
                v-if="certificateItemData.businessRegFile"
                :dataList="[certificateItemData.businessRegFile]"
              />
            </td>
          </tr>
          <tr>
            <th>통장 사본</th>
            <td colspan="3">
              <FileView
                v-if="certificateItemData.bankBookFile"
                :dataList="[certificateItemData.bankBookFile]"
              />
            </td>
          </tr>
          <tr>
            <th>세금계산서/계산서</th>
            <td colspan="3">
              <FileView
                v-if="certificateItemData.billFiles && certificateItemData.billFiles.length > 0"
                :dataList.sync="certificateItemData.billFiles"
                :isComments="true"
              />
            </td>
          </tr>
          <tr>
            <th>기타 증빙 문서</th>
            <td colspan="3">
              <FileView
                v-if="certificateItemData.etcFiles && certificateItemData.etcFiles.length > 0"
                :dataList.sync="certificateItemData.etcFiles"
                :isComments="true"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import FileView from "@/_approval/components/common/file/FileView";
import { unescape } from "lodash";
export default {
  name: "StatmentPartner",
  components: {
    FileView,
  },
  props: {
    certificateItemData: Object,
  },
  computed: {
    businessRegFile() {
      const businessRegFile = [];

      if (this.certificateItemData.businessRegFile) {
        businessRegFile.push(this.certificateItemData.businessRegFile);
      }
      return [businessRegFile];
    },
    partnerName() {
      return this.certificateItemData.userNum
        ? this.certificateItemData.partnerName
        : this.certificateItemData.partnerName;
    },
    corporateNum() {
      return this.certificateItemData.corporateNum
        ? this.certificateItemData.corporateNum
        : this.certificateItemData.corporateNum;
    },
  },
  methods: {
    unescape,
  },
};
</script>
